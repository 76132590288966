import Container from 'react-bootstrap/Container';
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown'
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import Button from 'react-bootstrap/Button';


function navColor(environment) {
    switch(environment) {
        case "local": return "dark"
        case "staging": return "secondary"
        case "production": return "success"
        default: return "dark"
    }
}

function SiteNav() {
    // SET THE COLOR OF THE NAV BAR DEPENDING ON THE ENVIRONMENT (local, staging, prod)
    
    const navbarColor = navColor(process.env.REACT_APP_ENVIRONMENT)

    const [userName, setUserName] = useState('');

    useEffect(() => {
        async function fetchUserData() {
            try {
                const currentUser = await getCurrentUser();
                setUserName(currentUser.username);
            } catch (error) {
                console.log('Error fetching user data: ', error);
            }
        }
        fetchUserData();
    }, []);


    return (
        <header>
            <Navbar bg={navbarColor} expand="lg" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand><b>Persephone: Garden Manager</b></Navbar.Brand>
                    <Navbar.Text>
                        Welcome <b>{userName}</b>!!!
                    </Navbar.Text>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-md-auto">
                        <Nav.Link href="/gardens">My Gardens</Nav.Link>
                            <Button variant="dark" onClick={() => handleSignOut()}>Sign Out</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

async function handleSignOut() {
    try {
      await signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
export default SiteNav;