import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function HomePage() {
    return (
        <Container>
            <Row className="px-4 my-5">
                <Col xs={4} sm={6}>
                    <Image
                        src="/img/logo.png"
                        fluid />
                </Col>
                <Col sm={6}>
                    <h1 className="font-weight-light">Persephone: Garden Manager</h1>
                    <p className="mt-4">
                        Welcome to Persephone, your favorite Garden Tool!
                        <br /><br />
                        This page is currently a work in progress.  Check Back Frequently for more stuff!</p> 
                </Col>
            </Row>
        </Container>
    )
}

export default HomePage