/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      caption
      url
      dateUploaded
      dateTaken
      createdAt
      updatedAt
      plantInstanceImagesId
      areaImagesId
      gardenImagesId
      owner
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      caption
      url
      dateUploaded
      dateTaken
      createdAt
      updatedAt
      plantInstanceImagesId
      areaImagesId
      gardenImagesId
      owner
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      caption
      url
      dateUploaded
      dateTaken
      createdAt
      updatedAt
      plantInstanceImagesId
      areaImagesId
      gardenImagesId
      owner
      __typename
    }
  }
`;
export const createPlantInstance = /* GraphQL */ `
  mutation CreatePlantInstance(
    $input: CreatePlantInstanceInput!
    $condition: ModelPlantInstanceConditionInput
  ) {
    createPlantInstance(input: $input, condition: $condition) {
      id
      nickname
      description
      images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      areaPlantInstancesId
      owner
      __typename
    }
  }
`;
export const updatePlantInstance = /* GraphQL */ `
  mutation UpdatePlantInstance(
    $input: UpdatePlantInstanceInput!
    $condition: ModelPlantInstanceConditionInput
  ) {
    updatePlantInstance(input: $input, condition: $condition) {
      id
      nickname
      description
      images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      areaPlantInstancesId
      owner
      __typename
    }
  }
`;
export const deletePlantInstance = /* GraphQL */ `
  mutation DeletePlantInstance(
    $input: DeletePlantInstanceInput!
    $condition: ModelPlantInstanceConditionInput
  ) {
    deletePlantInstance(input: $input, condition: $condition) {
      id
      nickname
      description
      images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      areaPlantInstancesId
      owner
      __typename
    }
  }
`;
export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $input: CreateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    createArea(input: $input, condition: $condition) {
      id
      name
      images {
        nextToken
        __typename
      }
      plantInstances {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      gardenAreasId
      owner
      __typename
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      name
      images {
        nextToken
        __typename
      }
      plantInstances {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      gardenAreasId
      owner
      __typename
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea(
    $input: DeleteAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    deleteArea(input: $input, condition: $condition) {
      id
      name
      images {
        nextToken
        __typename
      }
      plantInstances {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      gardenAreasId
      owner
      __typename
    }
  }
`;
export const createGarden = /* GraphQL */ `
  mutation CreateGarden(
    $input: CreateGardenInput!
    $condition: ModelGardenConditionInput
  ) {
    createGarden(input: $input, condition: $condition) {
      id
      name
      description
      images {
        nextToken
        __typename
      }
      areas {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGarden = /* GraphQL */ `
  mutation UpdateGarden(
    $input: UpdateGardenInput!
    $condition: ModelGardenConditionInput
  ) {
    updateGarden(input: $input, condition: $condition) {
      id
      name
      description
      images {
        nextToken
        __typename
      }
      areas {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGarden = /* GraphQL */ `
  mutation DeleteGarden(
    $input: DeleteGardenInput!
    $condition: ModelGardenConditionInput
  ) {
    deleteGarden(input: $input, condition: $condition) {
      id
      name
      description
      images {
        nextToken
        __typename
      }
      areas {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
