import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import SiteNav from "./ui-components/common/SiteNav";
import SiteFooter from "./ui-components/common/SiteFooter";
import HomePage from "./ui-components/home/HomePage";
import Garden from "./ui-components/garden/Garden";
import { Route, Routes } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import {
  Authenticator,
  Heading,
  Image,
  Text,
  View,
  useTheme
} from "@aws-amplify/ui-react";

function App() {

    //SETUP AUTHENTICATION CUSTOMIZATIONS
    const auth_components = {
        Header() {
          const { tokens } = useTheme();
    
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Heading level={2} color={tokens.colors.green[60]}>
                WELCOME TO
              </Heading>
              <Image 
                alt="Persephone Logo"
                src="/img/logo.png"
                width={250}
              />
              <Heading level={1} color={tokens.colors.green[60]}>
                PERSEPHONE
              </Heading>
            </View>
          );
        },
        Footer() {
          const { tokens } = useTheme();
    
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Text color={tokens.colors.green[60]}>
                &copy; All Rights Reserved
              </Text>
            </View>
          )
        }
      };

  return (
    <Authenticator loginMechanisms={['username']} components= {auth_components}>
    <View className="App">
      <SiteNav />
      <Routes>
        <Route path='*' element={<HomePage />} />
        <Route path='/' exact={true} element={<HomePage />} />
        <Route path='/gardens' element={<Garden />} />
      </Routes>
      <SiteFooter />
    </View>
    </Authenticator>
  );
};

export default App;