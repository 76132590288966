/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://eydygyfzzfhfljrinforcf5eua.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-idvwrwyzevhmlcq65suydsbqb4",
    "aws_cognito_identity_pool_id": "us-west-2:405dae3d-0cfa-409e-a3e3-3b6c742f3ce1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_BIdsAmHAd",
    "aws_user_pools_web_client_id": "6hnd3d3b3pm2nmlotddle657i0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "persephone7f5fd43fcda24fe0b950003bfbb3a4f97e1f5-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
