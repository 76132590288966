import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'

const env = process.env.REACT_APP_ENVIRONMENT

function SiteFooter() {
    return (
    <footer className="py-5 my-5">
        <Container className="px-4">
            <p className="text-end">
                Persephone (2024) Cupcake Dev : <a href="mailto:CupcakePlanning@gmail.com">Contact Us</a>
            </p>
            {
                env !== "production" && (
                    <Alert className="text-end" variant="warning">
                        Just for awareness, this is the {env} branch.
                    </Alert>
                )
            }
        </Container>
    </footer>
    )
}

export default SiteFooter;