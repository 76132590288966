import { useState } from "react";

import { Amplify } from "aws-amplify"
import { createGarden } from '../../graphql/mutations'
import { generateClient } from "aws-amplify/api";
import config from "../../amplifyconfiguration.json"

import { v4 as uuid } from 'uuid'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";



Amplify.configure(config)


function Garden() {
    const client = generateClient()
    const [gardenData, setGardenData] = useState({name: "", description: ""});

    const addNewGarden = async () => {
        try {
            const { name, description} = gardenData;

            const newGarden = {
                id: uuid(),
                name,
                description
            };

            console.log("My New Garden" + newGarden)
            //Persist new Garden
            const gardenResponse = await client.graphql({
                query: createGarden,
                variables: { input: newGarden}
            })

            console.log(gardenResponse)
        } catch (error) {
            console.error("Error adding new garden:", error);
        }
    }
    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>My Gardens</h1></Col>
            </Row>
            <Row>
                <Col className="px-2 my-2">
                    <Card style={{ width: '12rem' }}>
                        <Card.Img
                            src="/img/logo.png"
                            variant="top" />
                        <Card.Body>
                            <Card.Title>My Sample Garden</Card.Title>
                            <Card.Text>
                                This needs to be connected to the database eventually.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={3}>
                <h2>Add New Garden</h2>
                    <Form>
                        <Form.Group className="mb-3" controlId="gardenName">
                            <Form.Label>Name*</Form.Label>
                            <Form.Control type="text" placeholder="Name your garden" 
                                          value={gardenData.name}
                                          onChange={evt => setGardenData({...gardenData, name:evt.target.value})} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="gardenDescription">
                            <Form.Label>Description (Optional)</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Describe your garden..." 
                                          onChange={evt => setGardenData({...gardenData, desc:evt.target.value})} />
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={addNewGarden}>Add Garden &gt;&gt;</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default Garden